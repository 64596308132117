<template>
	<div style="width: 100%;">
		<el-row>
			<el-col class="top-col">
				<p class="top-col-img" @click="shoye"><img src="../assets/img/newlogo.png" alt=""></p>
			</el-col>
		</el-row>
		<el-row class="main_row">
			<el-col :span="6" style="margin-left: 270px; margin-top: 107px">
				<p style="font-size:40px; color: rgb(76,76,76)">让BIM作品秀起来</p>
				<p style="font-size: 20px; color: rgb(160,160,160);line-height: 50px;">你秀，我秀，大家一起都来秀一秀!</p>
			</el-col>
			<el-col class="login_tips" :offset="5">
				<el-row>
					<el-col class="login_tips_top" style="border-right: 1px solid #dddddd; color:#b7482c;" :span="12">
						登录
					</el-col>
					<el-col class="login_tips_top" :span="12" >
						<p @click='zhuce'>注册</p>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8" :offset="8" style="margin-bottom: 33px; margin-top: 33px">
						<img src="../assets/img/newlogo.png" alt="logo" style="width: 100%; display: block;">
					</el-col>
				</el-row>
				<el-row>
					<el-col style="margin-bottom: 23px">
						<el-input v-model="user" placeholder="请输入手机号" class="login_inp"></el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-input v-model="pass" type="password" placeholder="请输入登录密码" class="login_inp"   @keyup.enter.native="userLogin()"></el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-button type="primary" class="login_btn" @click="userLogin">登录</el-button>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="3" :offset="15" >
						<p> <a href="https://account.bimfilm.cn/bimfilm/forgetpwd?type1=1" target="_blank" style="color: #adafae">忘记密码</a></p>
					</el-col>
				</el-row>
			</el-col>


		</el-row>
	</div>
</template>
<script>
	import {
		requestLogin,
		requestStorage
	} from "../api/request";
	export default {
		data() {
			return {
				user: "", //登录时填写的用户（手机号）
				pass: "", //密码
			}
		},
		components: {

		},
		//函数方法
		methods: {
			shoye(){
				this.$router.push('/')
			},
			userLogin() {
				console.log(this.Account.GetSession)
				
				if (this.Account.GetSession() != null) {
					this.$router.push('/')
					return
				}
				
				let data_this = this;
				if (this.user === "" || this.pass === "") {
					this.$message({
						message: "账号密码不能为空",
						type: "warning",
					});
					return;
				}
				let paramData = {
					$router: this.$router,
				};
				let obj = Object();
				obj.account = this.user;
				obj.password = this.$md5(this.pass);
				this.Account.LogOut();
				requestLogin(obj).then(res => {
					if (res.code === 0) {
						// localStorage.setItem("token","f225fe8c1a4fc360153bdc390c383425");
						localStorage.setItem("token", res.ret.token);
						localStorage.setItem("session", JSON.stringify(res.ret));
						// data_this.$emit("closeDialog",false);
						requestStorage().then(res => {
							console.log('----------------1--------------')
							localStorage.setItem("storage", JSON.stringify(res.ret));
							localStorage.setItem("determine", true);
							this.$router.push('/');
							// setTimeout(function() {
							// 	history.go(0)
							// })
						})

						data_this.$message({
							message: '恭喜你，登录成功',
							type: 'success'
						});

					}
				})

			},
			zhuce(){
				window.open('https://account.bimfilm.cn/bimfilm/registeruser?type1=1');
			}
		},
		mounted() {
			console.log(this.$parent);
			// this.$parent.$refs.Rightfloatingframe.$el.style.display = 'none';
			this.$parent.$refs.headerTop.$el.style.display = 'none';
			// this.$parent.$refs.login.$el.style.display = 'none';
			// this.$parent.$refs.head.$el.style.display = 'none';
			// this.$parent.$refs.navBar.$el.style.display = 'none';
		},
		destroyed() {
			// this.$parent.$refs.Rightfloatingframe.$el.style.display = 'block';
			this.$parent.$refs.headerTop.$el.style.display = 'block';
			// this.$parent.$refs.login.$el.style.display = 'block';
			// this.$parent.$refs.navBar.$el.style.display = 'block';
		},
	};
</script>
<style scoped>
	@import "../style/common.css";

	.top-col {
		height: 87px;
		box-shadow: 0 7px 7px -7px #5E5E5E;
		margin-bottom: 7px;
	}

	.top-col-img {
		margin-top: 14px;
		margin-left: 270px;
		cursor: pointer;
	}

	.main_row {
		height: 67.3vh;
		background-color: #f8f8f8;
		background-image: url("../assets/img/NewLoginBack.jpg");
		background-repeat: no-repeat;
		background-position: 430px bottom;
		overflow: hidden;
	}

	.login_tips {
		margin-top: 48px;
		width: 582px;
		height: 516px;
		background-color: white;
		box-shadow: 0px 0px 6px #7e7e7e;
		border-radius: 5px;
	}

	.login_tips_top {
		height: 63px;
		cursor: pointer;
		line-height: 63px;
		text-align: center;
		border-bottom: 1px solid #dddddd;
		color: #4f4f4f;
	}
	.login_tips_top:hover{
		color:#b7482c ;
	}
	.login_inp {
		width: 319px;
		height: 44px;

		display: block;
		margin: 0 auto;
	}

	.login_btn {
		width: 319px;
		height: 44px;
		border-radius: 0 !important;
		background-color: #b7482c;
		display: block;
		margin: 37px auto 8px;
	}
	>>> .el-input__inner:focus{
		border-color:#b7482c;
	}
</style>
